import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import React, { useRef, createRef } from "react"
import styled from "styled-components"
import Icon from "../utilities/icons"

/*
const treatmentItems = require("../utilities/data/treatments.json")

const WaxingGroupItems = treatmentItems.filter(item => item.category === 1)
const EyeGroupItems = treatmentItems.filter(item => item.category === 2)
const MassageGroupItems = treatmentItems.filter(item => item.category === 3)
const NailsGroupItems = treatmentItems.filter(item => item.category === 4)
const OtherGroupItems = treatmentItems.filter(item => item.category === 5)  */

const Group = styled.div`
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  border-top: 2px solid #ef017c;

  h2 {
    margin: 0;
    font-size: 22px;
    padding: 20px;
  }

  ul {
    margin: 0 0 20px 0;
    padding-bottom: 10px;
  }

  li {
    list-style-type: none;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    border-bottom: 1px solid #f9f9f9;
    width: calc(100% - 40px);
    margin: 0 auto;
    cursor: pointer;
    align-items: center;

    &:last-of-type {
      border-bottom: 0;
    }

    &:hover {
      background: #f9f9f9;
    }
  }
  .treatment-title {
    display: block;
    font-weight: 400;
    font-size: 0.8rem;
    flex: 1;
    margin-left: 5px;
  }
  .treatment-price {
    display: inline-block;
    font-size: 14px;
    color: #333;
    font-weight: 400;
  }
  .treatment-time {
    font-size: 14px;
    color: #999;
    margin-right: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    align-self: flex-start;

    .timer-icon {
      margin-right: 2px;
      display: inline-block;

      svg {
        display: block;
      }
    }
  }
  .treatment-desc {
    margin: 5px 0 0 0;
    padding-left: 20px;
    display: none;
    width: 100%;
    font-size: 15px;
    p {
      margin-bottom: 5px;
      font-weight: 200;
    }
  }
  .active-desc {
    display: block;
  }

  .treatment-desc-extra {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .treatment-link {
    a {
      text-decoration: none;
      padding: 2px 10px;
      color: #ef017c;
      border: 1px solid #ef017c;
      background: #f9f9f9;
      border-radius: 5px;
      display: inline-flex;
      width: auto;
      align-items: center;

      svg {
        margin-left: 5px;
      }

      &:hover {
        background: #fff;
        color: #ef017c;
      }
    }
  }
`

const TreatmentGroups = ({ }) => {
  const toggleDescription = desc => {
    const currentItem = document.querySelector(`#${desc}`)
    currentItem.classList.contains("active-desc")
      ? currentItem.classList.remove("active-desc")
      : currentItem.classList.add("active-desc")
  }

  const TreatmentItem = ({ treatment }) => {

    let hasLink;
    if (treatment.LinkToPage !== "/treatments") {
      hasLink = treatment.LinkToPage;
    }

    console.log(`hasLink = ${hasLink}`)
    return (
      <li
        key={treatment.id}
        onClick={() => {
          toggleDescription(`treatment_desc_${treatment.id}`)
        }}
        className="treatment-item"
      >
        <Icon name="info" color="#999" width="14" height="14" />
        <span className="treatment-title">{treatment.TreatmentName}</span>

        <span className="treatment-price">
          &pound;{Number(treatment.TreatmentPrice).toFixed(2)}
        </span>

        <div id={`treatment_desc_${treatment.id}`} className="treatment-desc">
          <p>{treatment.TreatmentDescription}</p>
          <div className="treatment-desc-extra">
            <span className="treatment-time">
              {/*<div className="timer-icon">
                <Icon name="timer" color="#999" width="14" height="14" />
      </div> */}
              {/*treatment.time_taken*/}
            </span>

            {hasLink && hasLink != "" ? (
              <div>
                <span className="treatment-link">
                  <Link to={hasLink}>
                    View More{" "}
                    <Icon
                      name="right_arrow"
                      color="#ef017c"
                      width="14"
                      height="14"
                    />
                  </Link>
                </span>
              </div>
            ) : (
                ""
              )}
          </div>
        </div>
      </li>
    )
  }

  return (
    <div>
      <StaticQuery
        query={graphql`
          query Treatments {
            allCategoriesJson(sort: {fields: created_at, order: ASC})  {
    edges {
      node {
        id
        Name
        HumanReadableTitle
        treatments {
          id
          TreatmentName
          TreatmentPrice
          TreatmentDescription
          LinkToPage
          sortOrder
        }
      }
    }
  }
          }
        `}
        render={data => (
          <>

            <div>

              {data.allCategoriesJson.edges.map(item => {


                item.node.treatments.sort((a, b) => {
                  if (a.sortOrder === null && b.sortOrder === null) {
                    return 0; // both are null, no change in order
                  }
                  if (a.sortOrder === null) {
                    return 1; // null comes after any value
                  }
                  if (b.sortOrder === null) {
                    return -1; // null comes after any value
                  }
                  return a.sortOrder - b.sortOrder;
                });
                 
                  return (
                    <Group key={item.id} bgColor="#D53F8C" style={{ zIndex: "4" }}>
                    {console.log(item)}
                    <h2>{item.node.HumanReadableTitle}</h2>
                    {/*<TreatmentItem treatment={edge.node} />*/}
                    <div>
                      <ul>
                        {item.node.treatments.map(treatment => {
                          return (
                            <TreatmentItem key={treatment.id} treatment={treatment} />
                          )
                        }
                        )}
                      </ul>
                    </div>
                  </Group>
                )
                })}


            </div>
          </>
        )}
      />
    </div>

  )
}

export default TreatmentGroups

